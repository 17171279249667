.button-45 {
    position: relative;
    align-items: center;
    background-color: #000;
    background-position: 0 0;
    border-radius: 11px;
    color: #bcff4f;
    cursor: pointer;
    display: flex;
    font-weight: 700;
    padding: 4px 12px;
    text-align: left;
    text-decoration: none;
    text-shadow: none;
    text-underline-offset: 1px;
    transition: border .2s ease-in-out, box-shadow .2s ease-in-out;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    word-break: break-word;
}

.button-45:active,
.button-45:hover,
.button-45:focus {
    outline: 0;
}

.button-45:active {
    background-color: #bcff4f;
    box-shadow: rgba(0, 0, 0, 0.12) 0 1px 3px 0 inset;
    color: #000;
}

.button-45:hover {
    background-color: #bcff4f;
    color: #000;
}

.button-45:active:hover,
.button-45:focus:hover,
.button-45:focus {
    background-color: #bcff4f;
    box-shadow: rgba(0, 0, 0, 0.12) 0 1px 3px 0 inset;
    color: #000;
}


.button-45 .tooltip {
    display: none;
    position: absolute;
    top: 120%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #bcff4f;
    color: #000;
    padding: 5px;
    border-radius: 10px;
    white-space: nowrap;
    z-index: 1000;
    font-size: small;
    font-family:  'Pretendard';
    font-weight: 300;
  }
  

  .button-45:hover .tooltip {
    display: block;
  }